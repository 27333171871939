<template>
  <div class="funnel-area">
    <div class="row">
      <div
        :class="
          $storage.get('auth').user.user_type == 2
            ? 'col-md-6 col-lg-4'
            : 'col-md-6 col-lg-4'
        "
      >
        <div class="sale-grap salegrap3">
          <div class="title-grap">
            <h4>Activity</h4>
          </div>
          <div class="graph-boxhome">
            <apexcharts
              v-if="activityLoaded"
              height="280"
              type="bar"
              :options="activityChartOptions"
              :series="activityChartData"
            ></apexcharts>
          </div>
        </div>
      </div>
      <div
        :class="
          $storage.get('auth').user.user_type == 2
            ? 'col-md-6 col-lg-4'
            : 'col-md-6 col-lg-4'
        "
      >
        <div class="sale-grap salegrap4">
          <div class="title-grap">
            <h4>Appointments</h4>
          </div>
          <br />
          <div class="graph-boxhome dashboard-appt-grap">
            <apexcharts
              v-if="apptLoaded"
              width="100%"
              height="250"
              type="bar"
              :options="apptChartOptions"
              :series="apptChartData"
            ></apexcharts>
          </div>
        </div>
      </div>
      <div
        :class="
          $storage.get('auth').user.user_type == 2
            ? 'col-md-6 col-lg-4'
            : 'col-md-6 col-lg-4'
        "
      >
        <!-- Average Response Time -->
        <div class="sale-sold" style="height: 360px;">
          <div class="title-grap">
            <h4>Average Response Time</h4>
          </div>
          <div class="response-time">
            <img class="img-fluid" src="../../assets/img/avtime.svg" alt="" />
            <h3>{{ avgTime }} <span>Min</span></h3>
          </div>
        </div>
        <!-- Average Response Time -->
      </div>

      <!-- <div class="col-md-4">
            <div class="graph-area leased-sec-dashboard">
                <div class="grap-title row">
                    <div class="title-grap col-12">
                        <h4>Activity    </h4>
                    </div>
                    <div class="graph-line-titles col-12">
                        <div>
                            <span class="glt-2"></span> Out
                        </div>
                        <div>
                            <span class="glt-1"></span> In
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="height:200px;">
                        <bar-chart v-if="activityLoaded" :chartdata="activityChartdata.data" :height="200" :chartlabel="activityChartdata.labels" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="graph-area leased-sec-dashboard">
                <div class="grap-title row">
                    <div class="title-grap col-12">
                        <h4>Appointments</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="height:242px;">
                        <horizontal-bar-chart v-if="apptLoaded" :chartdata="apptChartdata.data" :height="250" :chartlabel="apptChartdata.labels" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="graph-area avg-resp-tym">
                <div class="row">
                    <div class="col-12">
                        <div class="grap-title">
                            <div class="title-grap">
                                <h4>Average Response Time</h4>
                            </div>
                        </div>
                        <div class="text-center">
                            <img src="../../assets/img/clock.jpg" alt="" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="text-center art-time">
                            {{avgTime}}<span class="art-unit">Min</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
  </div>
</template>

<script>
//import HorizontalBarChart from "../charts/HorizontalBarChart.vue"
//import BarChart from "../charts/BarChart"
import VueApexCharts from "vue-apexcharts";

import axios from "axios";
//import moment from 'moment'
//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: "GraphGrid2",
  data() {
    return {
      avgTime: "00",
      graph_filters_: {
        duration: "monthly",
        customDate: {},
        from: "",
        to: "",
        id: "",
        tm_id: "",
      },
      //activityChartdata: null,
      //apptChartdata: null,
      activityLoaded: false,
      apptLoaded: false,
      activityChartOptions: {
        chart: {
          type: "bar",
          height: 280,
          events: {
            click: () => {
              this.$root.$emit("action", "activity");
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Call", "Email", "Text", "Touches ™/Engs"],
        },
        yaxis: {},
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      activityChartData: [
        {
          name: "Out",
          data: [44, 55, 57],
        },
        {
          name: "In",
          data: [76, 85, 101],
        },
      ],
      apptChartOptions: {
        plotOptions: {
          bar: {
            horizontal: true,
            startingShape: "rounded",
            endingShape: "rounded",
            barHeight: "22%",
            distributed: true,
          },
        },
        chart: {
          events: {
            click: (event, chartContext, config) => {
              this.$root.$emit("action", "appointmentgraph", {
                index: config.dataPointIndex,
              });
              // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
              // if (config.dataPointIndex == 0) {
              //   this.$router.push("appointments?type=created");
              // } else if (config.dataPointIndex == 1) {
              //   this.$router.push("appointments?type=scheduled");
              // } else if (config.dataPointIndex == 2) {
              //   this.$router.push("appointments?type=confirmed");
              // }else if (config.dataPointIndex == 3) {
              //   this.$router.push("appointments?type=missed");
              // }else if (config.dataPointIndex == 4) {
              //   this.$router.push("appointments?type=shown");
              // }
            },
          },
        },
        colors: ["#793BE3", "#47CEFF", "#19BE93", "#FF470E", "#FFCB46"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: { show: false },
        xaxis: {
          categories: ["Created", " Scheduled", "Confirmed", "Missed", "Shown"],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      apptChartData: [
        {
          data: [0, 0, 0, 0, 0],
        },
      ],
    };
  },
  components: {
    apexcharts: VueApexCharts,
    //HorizontalBarChart,BarChart
  },
  created() {
    this.avgRespTime();
    this.activityData();
    this.apptData();

    let vm = this;
    this.$root.$on("action", function (action, data) {
      if (action == "update_dashboard") {
        vm.graph_filters_ = data;
        vm.activityLoaded = false;
        vm.apptLoaded = false;
        vm.avgRespTime();
        vm.activityData();
        vm.apptData();
      }
    });
  },
  methods: {
    avgRespTime() {
      axios
        .post("avg_resp_time", this.graph_filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res.data.success)
          if (res.data.success) {
            this.avgTime = res.data.success.toString().replace(".", ":");
          } else {
            this.avgTime = "00";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    activityData() {
      axios
        .post("activity_counter", this.graph_filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res.data.success)
          if (res.data.success) {
            //this.activityChartdata = res.data.success
            this.activityChartOptions.labels = res.data.success.labels;
            this.activityChartData = res.data.success.data;
            setTimeout(() => {
              this.activityLoaded = true;
            }, 1000);
          } else {
            this.activityLoaded = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    apptData() {
      axios
        .post("appt_counter", this.graph_filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res.data)
          if (res.data.success) {
            //this.apptChartdata = res.data.success
            this.apptChartOptions.labels = res.data.success.labels;
            this.apptChartData = res.data.success.data;
            setTimeout(() => {
              this.apptLoaded = true;
            }, 1000);
          } else {
            this.apptLoaded = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.leased-sec-dashboard {
  padding: 30px 20px;
}
.avg-resp-tym img {
  max-width: 190px;
}
.avg-resp-tym .art-time {
  color: #3150a1;
  font-size: 50px;
  font-weight: 600;
}
.avg-resp-tym .art-unit {
  font-size: 16px;
  color: #8a91a5;
  font-weight: 500;
  margin-left: 15px;
}
</style>
