import { render, staticRenderFns } from "./myReports.vue?vue&type=template&id=0befa40e&"
import script from "./myReports.vue?vue&type=script&lang=js&"
export * from "./myReports.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports