<template>
  <div class="salegrap-area">
    <div class="row">
      <div
        :class="
          $storage.get('auth').user.user_type == 2
            ? 'col-md-6 col-lg-4'
            : 'col-md-6 col-lg-4'
        "
      >
        <div class="sale-grap salegrap1">
          <div class="title-grap">
            <h4>Leads vs Sold</h4>
          </div>
          <div class="graph-boxhome">
            <apexcharts
              v-if="leadLoaded"
              height="280"
              type="line"
              :options="leadsChartOptions"
              :series="leadsChartData"
            ></apexcharts>
          </div>
        </div>
      </div>
      <div
        :class="
          $storage.get('auth').user.user_type == 2
            ? 'col-md-6 col-lg-4'
            : 'col-md-6 col-lg-4'
        "
      >
        <div class="sale-grap salegrap2">
          <div class="title-grap">
            <h4>Source Type</h4>
          </div>
          <div class="graph-boxhome">
            <apexcharts
              v-if="typeLoaded"
              type="bar"
              height="260"
              :options="sourceChartOptions"
              :series="sourceChartData"
            ></apexcharts>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <!-- Funnel -->
        <div class="funnel-box sale-funnel">
          <div class="funnel-title">
            <h3>Sales Funnel</h3>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="funnel-img">
                <img
                  class="img-fluid"
                  src="../../assets/img/funnel3.svg"
                  alt=""
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="funnel-list">
                <ul>
                  <li>
                    <a href="#"
                      >Status
                      <h5>Leads</h5></a
                    >
                  </li>
                  <li
                    v-show="funneldata"
                    v-for="(funnel, index) in funnel"
                    :key="index"
                  >
                    <router-link v-if="funnel.title == 'Received'" :to="('/leads')"
                        ><span
                          class="dots text-wrap"
                          :class="'dot' + Number(index + 1)"
                        ></span>
                        {{ funnel.title }}
                        <h5>{{ funnel.leads }}</h5>
                    </router-link>
                    <router-link v-if="funnel.title == 'Contacted'" :to="('/leads?status=2')"
                      ><span
                        class="dots text-wrap"
                        :class="'dot' + Number(index + 1)"
                      ></span>
                      {{ funnel.title }}
                      <h5>{{ funnel.leads }}</h5></router-link
                    >
                    <router-link v-if="funnel.title == 'Scheduled'" :to="('/leads?status=3')"
                      ><span
                        class="dots text-wrap"
                        :class="'dot' + Number(index + 1)"
                      ></span>
                      {{ funnel.title }}
                      <h5>{{ funnel.leads }}</h5></router-link
                    >
                    <router-link v-if="funnel.title == 'Visits'" :to="('/leads?status=4')"
                      ><span
                        class="dots text-wrap"
                        :class="'dot' + Number(index + 1)"
                      ></span>
                      {{ funnel.title }}
                      <h5>{{ funnel.leads }}</h5></router-link
                    >
                    <router-link v-if="funnel.title == 'Demo'" :to="('/leads?status=5')"
                      ><span
                        class="dots text-wrap"
                        :class="'dot' + Number(index + 1)"
                      ></span>
                      {{ funnel.title }}
                      <h5>{{ funnel.leads }}</h5></router-link
                    >
                    <router-link v-if="funnel.title == 'Sold'" :to="('/leads?status=6')"
                      ><span
                        class="dots text-wrap"
                        :class="'dot' + Number(index + 1)"
                      ></span>
                      {{ funnel.title }}
                      <h5>{{ funnel.leads }}</h5></router-link
                    >
                    <router-link v-if="funnel.title == 'Lost'" :to="('/leads?status=7')"
                      ><span
                        class="dots text-wrap"
                        :class="'dot' + Number(index + 1)"
                      ></span>
                      {{ funnel.title }}
                      <h5>{{ funnel.leads }}</h5></router-link
                    >
                    <router-link v-if="funnel.title == 'Write Ups'" :to="('/leads')"
                      ><span
                        class="dots text-wrap"
                        :class="'dot' + Number(index + 1)"
                      ></span>
                      {{ funnel.title }}
                      <h5>{{ funnel.leads }}</h5></router-link
                    >
                    </li>
                </ul>
              </div>
              <!-- <table class="table">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th class="text-center">Lead</th>
                    <th class="text-center">%</th>
                  </tr>
                </thead>
                <tbody v-show="funneldata">
                  <tr v-for="(funnel, index) in funnel" :key="index">
                    <td>
                      <span
                        class="dots text-wrap"
                        :class="'dot' + Number(index + 1)"
                      ></span
                      >{{ funnel.title }}
                    </td>
                    <td class="text-center">{{ funnel.leads }}</td>
                    <td class="text-center">
                      {{ funnel.leads | getPercentage(funnel.received) }}%
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </div>
          <div class="sale-table">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Lead / Contact%</th>
                    <th>Contact / Appt%</th>
                    <th>Appts / Show%</th>
                    <th>Appt / Sold%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ data.contacted_per }}%</td>
                    <td>{{ data.schedule_per }}%</td>
                    <td>{{ data.visit_per }}%</td>
                    <td>{{ data.sold_per }}%</td>
                    <!-- <td>{{funnel.touches | getPercentage(funnel.total_leads,0)}}%</td>
                                    <td>{{funnel.total_appointments | getPercentage(funnel.total_leads,0)}}%</td>
                                    <td>{{funnel.total_appt_shown | getPercentage(funnel.total_leads,0)}}%</td>
                                    <td>{{funnel.sold | getPercentage(funnel.total_leads,0)}}%</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Funnel -->
      </div>

      <!-- <div class="col-md-4">
            <div class="graph-area leased-sec-dashboard">
                <div class="grap-title row">
                    <div class="title-grap col-12">
                        <h4>Sold / Leased</h4>
                    </div>
                    <div class="graph-line-titles col-12">
                        <div>
                            <span class="glt-1"></span> Leased
                        </div>
                        <div>
                            <span class="glt-2"></span> Sold
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="height:200px;">
                        <line-chart v-if="leadLoaded" :chartdata="leadsChartdata.data" :height="200" :chartlabel="leadsChartdata.labels" />
                    </div>
                </div>
            </div>
        </div> -->

      <!-- <div class="col-md-4">
            <div class="graph-area leased-sec-dashboard">
                <div class="grap-title row">
                    <div class="title-grap col-12">
                        <h4>Source Type</h4>
                    </div>
                    <div class="graph-line-titles col-12">
                        <div>
                            <span class="glt-1"></span> Walking In
                        </div>
                        <div>
                            <span class="glt-2"></span> Phone
                        </div>
                        <div>
                            <span class="glt-3"></span> Internet
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <bar-chart v-if="typeLoaded" :chartdata="sourceTypeChartdata.data" :height="200" :chartlabel="sourceTypeChartdata.labels" />
                    </div>
                </div>
            </div>
        </div> -->

      <!-- <div class="col-md-4">
            <div class="funnel-box">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="funnel-title">
                            <h3>Funnel</h3>
                        </div>
                        <div class="funnel-img">
                            <img class="img-fluid" src="../../assets/img/funnel.svg" alt="" />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="funnel-list">
                            <ul>
                                <li>
                                    <a href="#" class="pb-0">
                                        Name
                                        <span class="float-right">Leads</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span class="funnel-1"></span> Received
                                        <h5>{{funnel.total_leads}}</h5>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span class="funnel-2"></span> Contacted
                                        <h5>{{funnel.touches}}</h5>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span class="funnel-3"></span> Appointment
                                        <h5>{{funnel.total_appointments}}</h5>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span class="funnel-4"></span> Apps Shown
                                        <h5>{{funnel.total_appt_shown}}</h5>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <span class="funnel-5"></span> Delivered
                                        <h5>{{funnel.sold}}</h5>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="funnel-bottom-wrapper">
                            <div >
                                <div class="row text-center">
                                    <div class="col-md-3 funnel-bottom-head p-1">Contacted</div>
                                    <div class="col-md-3 funnel-bottom-head p-1">Appointment</div>
                                    <div class="col-md-3 funnel-bottom-head p-1">Apps Shown</div>
                                    <div class="col-md-3 funnel-bottom-head p-1">Delivered</div>
                                </div>
                                <div class="row text-center">
                                    <div class="col-md-3 funnel-bottom-val"><span class="float-left">%</span>100%</div>
                                    <div class="col-md-3 funnel-bottom-val">75%</div>
                                    <div class="col-md-3 funnel-bottom-val">43%</div>
                                    <div class="col-md-3 funnel-bottom-val">10%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
  </div>
</template>

<script>
//import LineChart from "../charts/LineChart"
//import BarChart from "../charts/BarChart"
import axios from "axios";
//import HorizontalBarChart from "../charts/HorizontalBarChart.vue"
import VueApexCharts from "vue-apexcharts";

//import moment from 'moment'
//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: "GraphGrid2",
  data() {
    return {
      data: {},
      funneldata: false,
      graph_filters_: {
        duration: "monthly",
        customDate: {},
        from: "",
        to: "",
        id: "",
        tm_id: "",
      },
      //leadsChartdata: null,
      //sourceTypeChartdata: null,
      leadLoaded: false,
      typeLoaded: false,
      funnel: {},
      sourceChartOptions: {
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "15%",
            endingShape: "rounded",
            distributed: true,
          },
        },
        chart: {
          events: {
            click: (event, chartContext, config) => {
              this.$root.$emit("action", "activitygraph", {
                index: config.dataPointIndex,
              });
              //   if (config.dataPointIndex == 0) {
              //     if(this.graph_filters_.duration == "custom"){
              //        this.$router.push("leads?type=1&duration="+this.graph_filters_.duration+'&start='+this.graph_filters_.customDate.startDate);
              //     }
              //   this.$router.push("leads?type=1&duration="+this.graph_filters_.duration);
              // } else if (config.dataPointIndex == 1) {
              //   this.$router.push("leads?type=2&duration="+this.graph_filters_.duration);
              // } else if (config.dataPointIndex == 2) {
              //   this.$router.push("leads?type=3&duration="+this.graph_filters_.duration);
              // }
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: ["Walk In", "Phone", "Internet"],
        },
        yaxis: {
          title: {},
        },
        fill: {
          opacity: 1,
          //colors:['#008ffb', '#E91E63', '#9C27B0']
        },
        // tooltip: {
        //     y: {
        //         formatter: function(val) {
        //             //return "$ " + val + " K"
        //             return val + " K"
        //         }
        //     }
        // },
        colors: ["#008ffb", "#00e396", "#feb019"],
        // chart: {
        //     id: 'basic-bar'
        // }
      },
      sourceChartData: [],

      leadsChartOptions: {
        stroke: {
          width: [2, 2, 2],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "30%",
          },
        },
        fill: {
          opacity: [1, 1, 1],
          gradient: {
            inverseColors: false,
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        markers: {
          size: 0,
        },
        xaxis: {
          type: "text",
          labels: {
            show: false,
          },
        },
        yaxis: {
          min: 0,
        },
        colors: ["#00e396", "#008ffb"],
        tooltip: {
          shared: true,
          //intersect: false,
          // y: {
          //     formatter: function(y) {
          //         if (typeof y !== "undefined") {
          //             return y.toFixed(0) + " points";
          //         }
          //         return y;

          //     }
          // }
        },
      },
      leadsChartData: [],
    };
  },
  components: {
    apexcharts: VueApexCharts,
  },
  created() {
    this.leadsGraph();
    this.sourceTypeGraph();
    this.leadFunnelData();
    this.funnelReport();
    let vm = this;
    this.$root.$on("action", function (action, data) {
      if (action == "update_dashboard") {
        vm.graph_filters_ = data;
        vm.leadLoaded = false;
        vm.typeLoaded = false;
        vm.leadsGraph();
        vm.sourceTypeGraph();
        vm.leadFunnelData();
        vm.funnelReport();
      }
    });
  },
  methods: {
    leadsGraph() {
      // if (dur != '') {
      //     this.graph_filters_.duration = 'year'
      // }
      axios
        .post("dashboard_leads_graph", this.graph_filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res.data.success)
          if (res.data.success) {
            this.leadsChartOptions.labels = res.data.success.labels;
            this.leadsChartData = res.data.success.data;
            setTimeout(() => {
              this.leadLoaded = true;
            }, 1000);
          } else {
            this.leadLoaded = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sourceTypeGraph() {
      // if (this.graph_filters_.duration == 'year') {
      //     this.graph_filters_.duration = 'monthly'
      // }
      axios
        .post("source_counter", this.graph_filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res.data)
          if (res.data.success) {
            this.sourceChartOptions.xaxis.categories = res.data.success.labels;
            this.sourceChartData = res.data.success.data;
            setTimeout(() => {
              this.typeLoaded = true;
            }, 1000);
          } else {
            this.typeLoaded = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    leadFunnelData() {
      axios
        .post("dashboard_funnel", this.graph_filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          //console.log(res.data)
          if (res.data.success) {
            this.data = res.data.success;
            // var arr = [
            //   {
            //     title: "Active",
            //     leads: res.data.success.active,
            //   },
            //   {
            //     title: "Contacted",
            //     leads: res.data.success.contacted,
            //   },
            //   {
            //     title: "Scheduled",
            //     leads: res.data.success.schedule,
            //   },
            //   {
            //     title: "Visit",
            //     leads: res.data.success.visit,
            //   },
            //   {
            //     title: "Demo",
            //     leads: res.data.success.demo,
            //   },
            // ];
            // this.funnel = arr.sort((a, b) => {
            //   return a.leads - b.leads;
            // });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    funnelReport() {
      this.funneldata = false;
      axios
        .post("leads_report_funnel_stats", this.graph_filters_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.funneldata = true;
            var arr = [
              {
                title: "Received",
                leads: res.data.success.total_received,
                received: res.data.success.total_received,
              },
              {
                title: "Contacted",
                leads: res.data.success.total_contacted,
                received: res.data.success.total_received,
              },
              {
                title: "Scheduled",
                leads: res.data.success.total_appointments,
                received: res.data.success.total_received,
              },
              {
                title: "Demo",
                leads: res.data.success.demo,
                received: res.data.success.total_received,
              },

              {
                title: "Visits",
                leads: res.data.success.visits,
                received: res.data.success.total_received,
              },

              {
                title: "Write Ups",
                leads: res.data.success.desked,
                received: res.data.success.total_received,
              },
              {
                title: "Sold",
                leads: res.data.success.total_delivered,
                received: res.data.success.total_received,
              },
           
              {
                title: "Lost",
                leads: res.data.success.total_lost,
                received: res.data.success.total_received,
              },
              // {
              //   title: "Appt Shown",
              //   leads: res.data.success.total_apps_shown,
              //   received: res.data.success.total_received,
              // },
            ];
            this.funnel = arr;
          }
        })
        .catch((err) => {
          this.funneldata = false;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.sale-funnel .funnel-img {
  margin-top: 10px;
}
.sale-funnel .funnel-list li a {
  display: block;
  color: #a2a8b8;
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 2px 0;
  text-decoration: none;
  margin-bottom: 0;
}

.funnel-img img {
  margin: 0 auto;
  text-align: center;
  display: block;
  height: 150px;
}

.sale-funnel .table {
  width: 100%;
  margin-bottom: 0;
  color: #212529;
  margin-top: 0;
}
.dot1 {
  background: #6320d6 !important;
}

.dot2 {
  background: #54b9de !important;
}

.dot3 {
  background: #55c762 !important;
}

.dot4 {
  background: #d300ff !important;
}

.dot5 {
  background: #ffcb46 !important;
}
.dot6 {
  background: #848484 !important;
}
.dot7 {
  background: #0066ff !important;
}

.dot8 {
  background: #eb5757 !important;
}
</style>