<template>
    <DashboardContainer>
        <counter-tabs v-if="$storage.get('auth').user.user_type != 2"/>
        <GraphGrid1 v-if="$storage.get('auth').user.user_type != 2"/>
        <GraphGrid2 v-if="$storage.get('auth').user.user_type != 2"/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import CounterTabs from '../components/dashboard/CounterTabs'
import GraphGrid1 from '../components/dashboard/GraphGrid1'
import GraphGrid2 from '../components/dashboard/GraphGrid2'

export default {
    name: 'myReportsC',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    components: {
        DashboardContainer,
        CounterTabs,
        GraphGrid1,
        GraphGrid2
    }
}
</script>